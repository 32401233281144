<template>
  <Layout>
    <div class="container">
      <div id="signupForm" class="container is-fluid">
        <h1>
          <span v-if="!edit">Create a New User</span>
          <span v-else>Edit User</span>
        </h1>
        <form @submit="submitForm($event)" novalidate>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="email">Email Address</label>
              <div class="control">
                <input
                  v-model="user.email"
                  ref="email"
                  name="email"
                  class="input"
                  :class="{ 'is-danger': error.email }"
                  type="email"
                  placeholder="john.doe@company.com"
                  required="required"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.email }">{{ error.email }}</p>
            </div>
          </div>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="name">Name</label>
              <div class="control">
                <input
                  v-model="user.name.first"
                  ref="name"
                  name="name"
                  placeholder="John"
                  type="text"
                  class="input"
                  :class="{ 'is-danger': error.name }"
                  required="required"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.name }">{{ error.name }}</p>
            </div>
          </div>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="lastName">Last Name</label>
              <div class="control">
                <input
                  v-model="user.name.last"
                  ref="lastName"
                  name="lastName"
                  placeholder="Doe"
                  type="text"
                  class="input"
                  :class="{ 'is-danger': error.lastName }"
                  required="required"
                />
              </div>
              <p class="help" :class="{ 'is-danger': error.lastName }">{{ error.lastName }}</p>
            </div>
          </div>
          <template v-if="edit && isAdmin">
            <div class="fieldContainer">
              <div class="field">
                <label class="label" for="password">
                  Password
                  <span
                    class="icon right tooltip is-tooltip-left is-tooltip-multiline"
                    data-tooltip="The password must be between 8 and 15 characters and contain lower case, cappital letters, numbers and #, @ or * "
                  >
                    <icon :icon="['fas', 'question-circle']" />
                  </span>
                </label>
                <div class="control has-icons-right">
                  <input
                    v-model="newPassword"
                    ref="password"
                    name="password"
                    type="password"
                    class="input"
                    :class="{ 'is-danger': error.password }"
                    :pattern="passwordPattern"
                    required="required"
                  />
                  <span class="icon is-medium is-right">
                    <icon :icon="['fas', 'lock']" />
                  </span>
                </div>
                <p class="help" :class="{ 'is-danger': error.password }">{{ error.password }}</p>
              </div>
            </div>
            <div v-if="isPasswordValid" class="fieldContainer">
              <div class="field">
                <label class="label" for="confirmPassword">Confirm Password</label>
                <div class="control">
                  <input
                    v-model="confirmNewPassword"
                    name="confirmPassword"
                    type="password"
                    class="input"
                    :class="{ 'is-danger': error.confirmPassword }"
                    :pattern="passwordPattern"
                    required="required"
                  />
                </div>
                <p
                  class="help"
                  :class="{ 'is-danger': error.confirmPassword }"
                >{{ error.confirmPassword }}</p>
              </div>
            </div>
          </template>
          <div class="fieldContainer">
            <div class="field">
              <label class="label" for="role">Role</label>
              <div class="control">
                <div class="select">
                  <select
                    v-model="user.role"
                    ref="role"
                    name="role"
                    class="input"
                    :class="{ 'is-danger': error.role }"
                    required="required"
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
              <p class="help" :class="{ 'is-danger': error.role }">{{ error.role }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="fieldContainer">
                <div class="field">
                  <label class="label" for="company">Company</label>
                  <div class="control">
                    <div class="select">
                      <select
                        v-model="user.company"
                        ref="company"
                        name="company"
                        class="input"
                        :class="{ 'is-danger': error.company }"
                        required="required"
                      >
                        <option value="default" disabled="disabled">Select from List</option>
                        <option
                          v-for="(company, index) in companies"
                          :key="index"
                          :value="company"
                        >{{ company.name }}</option>
                      </select>
                    </div>
                  </div>
                  <p class="help" :class="{ 'is-danger': error.company }">{{ error.company }}</p>
                </div>
              </div>
            </div>
          </div>
          <p v-if="error.general" class="help is-danger">{{ error.general }}</p>
          <button type="submit" class="button is-fullwidth is-primary text-capitalize">
            <span v-if="!edit">Add User</span>
            <span v-else>Update</span>
          </button>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'newUser',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  data() {
    return {
      error: {
        general: null,
        email: null,
        name: null,
        lastName: null,
        password: null,
        confirmPassword: null,
        company: null,
        role: null,
      },
      user: {
        email: '',
        name: {
          first: '',
          last: ''
        },
        company: 'default',
        role: 'user',
      },
      companies: [],
      passwordPattern: '^[a-zA-Z0-9@*#]{8,15}$',
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  computed: {
    edit() {
      return this.$router.currentRoute?.params?.user || false;
    },
    isAdmin() {
      return $cookies.get($formsConfig.core.cookieName).role === 'admin';
    },
    isPasswordValid() {
      return !/^\s*$/.test(this.newPassword);
    }
  },
  async beforeMount() {
    // Fetch the list of the companies.
    const response = await axios.get($formsConfig.core.api.companies);
    this.companies = response.data;
    if (this.edit) {
      const user = this.$router.currentRoute.params.user;
      this.user = {
        email: user.username,
        name: user.name,
        company: this.companies.find(item => item._id === user.company._id),
        role: user.role,
      };
    }
  },
  methods: {
    /**
     * This function performs the data validation and the
     * data submission if the form is valid.
     * @param {Event} evt The event will be used to cancel the
     * submission in case the form is invalid.
     * @returns boolean
     */
    async submitForm(evt) {
      // Cancel the default action for the form as we will send an XHR request.
      evt.preventDefault();
      if (this.validateForm()) {
        const user = {
          email: this.user.email,
          username: this.user.email,
          company: this.user.company._id,
          name: this.user.name,
          role: this.user.role,
        };
        if (!this.edit) {
          //Axios add a new user
          try {
            const response = await axios.post(
              $formsConfig.core.api.users,
              user
            );
            if (response.status === 200) {
              this.$router.push('/users');
            }
          } catch (e) {
            this.error.general =
              e?.response?.data?.message || 'Something went wrong';
          }
        } else {
          //Axios update a user
          const userId = this.$router.currentRoute.params.user.id;
          if (this.isPasswordValid) {
            user.password = this.newPassword;
          }
          try {
            const response = await axios.put(
              $formsConfig.core.api.users + userId,
              { user }
            );
            if (response.status === 200) {
              this.$router.push('/users');
            }
          } catch (e) {
            this.error.general =
              e?.response?.data?.message || 'Something went wrong';
          }
        }
      }
    },
    validateForm() {
      // Clear validations.
      this.error = {
        general: null,
        email: null,
        name: null,
        password: null,
        confirmPassword: null,
        company: null,
        role: null,
      };
      let isValid = true;
      for (const key in this.$refs) {
        if (this.$refs.hasOwnProperty(key)) {
          const field = this.$refs[key];
          if (field.name === 'company' && this.user.company === 'default') {
            // Validate special case for company field.
            isValid = false;
            this.error[key] = 'This field is required.';
          } else if (field.name === 'password') {
            // Validate special case for password field.
            if (this.newPassword) {
              if (field.checkValidity()) {
                if (this.newPassword !== this.confirmNewPassword) {
                  isValid = false;
                  this.error.confirmPassword = `Passwords don't match.`;
                }
              } else {
                isValid = false;
                this.error[key] = field.validationMessage;
              }
            }
          } else {
            isValid = field.checkValidity();
            if (!isValid) {
              this.error[key] = field.validationMessage;
            }
          }
        }
      }
      return isValid;
    }
  }
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  @extend %fieldContainer;
}

.is-fluid {
  align-items: center;
  background: $background-panels;
  border-radius: $control-radius;
  h1 {
    margin-bottom: 30px;
    text-align: center;
  }
}

#signupForm {
  padding: 15px 250px 15px 250px;
  margin: 5px 98px 0 92px;
  .select {
    width: 100%;
  }
}

.link {
  color: $color-link-text;
  text-align: right;
}
</style>
